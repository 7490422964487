<template>
  <div class="m_index_content">
    <el-container>
      <el-aside :style="asideStyle">
        <m-navbar></m-navbar>
      </el-aside>
      <el-container>
        <el-header>
          <m-header></m-header>
        </el-header>
        <el-main>
          <div class="m_index_navbar_content">
            <m-tabbar></m-tabbar>
          </div>
          <div class="m_index_main_content">
            <router-view />
          </div>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>
<script>
import mNavbar from "@/components/navBar/index.vue";
import mHeader from "@/components/header/index.vue";
import mTabbar from "@/components/tabbar/index.vue";
export default {
  directives: {},
  data() {
    return {
      isCollapse: true,
      asideStyle: {
        width: "auto",
      },
    };
  },
  props: {},
  computed: {},
  components: { mHeader, mNavbar,mTabbar },
  created() {},
  mounted() {},
  methods: {},
  beforeDestroy() {},
  destroyed() {},
};
</script>
<style>
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  min-height: 400px;
}
</style>
<style lang="less" scoped>
@import "../../assets/css/index/index.less";
</style>
