<template>
	<div class="m_header_content clearfix">
		<div class="el-icon-s-fold m_header_menu_tool fl" v-if="fold" @click="changeFold"></div>
		<div class="el-icon-s-unfold m_header_menu_tool fl" v-else @click="changeFold"></div>
		<div class="m_header_mine fr">
			<el-popover placement="bottom" trigger="click" width="90">
				<div class="m_user_tools">
					<div class="m_user_tools_item" @click="showPwd">修改密码</div>
					<div class="m_user_tools_item" @click="logout">退出</div>
				</div>
				<span slot="reference">{{ userInfo.userName }}<span class="el-icon-caret-bottom"></span
        ></span>
			</el-popover>
		</div>
		<el-dialog :close-on-click-modal="false" title="修改密码" :visible.sync="dialogVisible" :modal="false" v-el-drag-dialog width="600px">
			<div class="m_dialog_top" :style="dialogTopStyle">
				<el-form ref="form" :model="form" label-width="100px" :rules="rules" style="padding-right: 10px">
					<el-form-item label="老密码" prop="oldpwd">
						<el-input v-model="form.oldpwd"></el-input>
					</el-form-item>
					<el-form-item label="新密码" prop="newpwd">
						<el-input v-model="form.newpwd"></el-input>
					</el-form-item>
				</el-form>
			</div>
			<div class="m_dialog_bottom" style="text-align: center;">
				<el-button type="primary" @click="save" size="mini" class="m_btn_save">保存</el-button>
				<el-button @click="dialogVisible = false" size="mini" class="m_btn_cancel">取消</el-button>
			</div>
		</el-dialog>
	</div>
</template>
<script>
	import elDragDialog from "@/directive/el-drag-dialog";
	import Bus from "@/utils/bus";
	import { logOut,editPassWord } from "@/api/base";
	export default {
		name: "mHeader",
		directives: {
			elDragDialog
		},
		data() {
			return {
				fold: true,
				dialogVisible: false,
				form: {
					userId: 0,
					oldpwd: '',
					newpwd: ''
				},
				rules: {
					oldpwd: [{
						required: true,
						message: "内容不能为空",
						trigger: "blur"
					}],
					newpwd: [{
						required: true,
						message: "内容不能为空",
						trigger: "blur"
					}]
				},
			}
		},
		props: {
			borderData: {},
		},
		computed: {
			userInfo() {
				return this.$store.getters.userInfo || {};
			},
			dialogTopStyle() {
				return {
					maxHeight: document.body.offsetHeight * 0.6 + "px",
					height:'200px'
				};
			}
		},
		components: {},
		created() {},
		mounted() {},
		methods: {
			changeFold() {
				this.fold = !this.fold;
				Bus.$emit("foldChange", !this.fold);
			},
			logout() {
				this.$confirm("确认退出系统?")
					.then(async(_) => {
						const res = await logOut();
						const {
							pageCount,
							results
						} = res;
						console.log(results);
						this.$store.commit("user/SET_TOKEN", null);
						this.$store.commit("user/SET_USERINFO", null);
						this.$router.push("/login");
					})
					.catch((_) => {});
			},
			showPwd() {
				this.form.userId = this.userInfo.userId;
				this.dialogVisible = true;
			},
			save() {
				this.$refs.form.validate((valid) => {
					if(valid) {
						this.update();
					} else {
						return false;
					}
				});
			},
			async update() {
				const res = await editPassWord(this.form);
				const {
					pageCount,
					results,
					code
				} = res;
				this.$message({
					message: "修改成功，请重新登录",
					type: "success",
				});
				if(code == 0){
					this.$store.commit("user/SET_TOKEN", null);
					this.$store.commit("user/SET_USERINFO", null);
					this.$router.push("/login");
					this.dialogVisible = false;
				}
			}
		},
	};
</script>
<style lang="less" scoped>
	@import "../../assets/css/header/header.less";
</style>