<template>
  <div class="zf_tabbar_content">
    <div
      :class="
        isActive === '/home' ? 'zf_tabbar_item isactive' : 'zf_tabbar_item'
      "
      @click="toRouter('/home')"
    >
      <span title="首页">首页</span>
    </div>
    <div
      :class="
        isActive === item.path ? 'zf_tabbar_item isactive' : 'zf_tabbar_item'
      "
      v-for="(item, i) in tabList"
      :key="i"
      @click="toRouter(item.path)"
    >
      <span :title="item.title">{{ item.title }}</span>
      <span
        class="el-icon-close zf_tabbar_item_close"
        @click.stop="closeTab(item.path)"
      ></span>
    </div>
  </div>
</template>
<script>
export default {
  name: "mTabbar",
  data() {
    return {
      tabList: [],
      isActive: "/home",
      oldIsActive: "",
    };
  },
  created() {
    if (this.$route.name !== "home") {
      this.tabList.push({
        name: this.$route.name,
        path: this.$route.path,
        title: this.$route.meta.title || this.$route.params.title,
      });
    }
    this.isActive = this.$route.path;
  },
  computed: {},
  mounted() {},
  methods: {
    // 点击跳转
    toRouter(path) {
      this.$router.push(path);
    },
    // 点击关闭
    closeTab(path) {
      if (this.tabList.length === 1) {
        this.$router.push("/home");
        this.tabList = [];
        return;
      }

      this.tabList = this.tabList.filter((item) => item.path !== path);
      if (path !== this.isActive) {
        this.oldIsActive = this.tabList[0].path;
        return;
      }
      this.isActive =
        this.oldIsActive === path ? this.tabList[0].path : this.oldIsActive;
      this.$router.push(this.isActive);
    },
  },
  watch: {
    $route(value) {
      console.log(value)
      if (value.name === "home") {
        let oldIsActive = this.isActive;
        this.oldIsActive = oldIsActive;
        this.isActive = value.path;
        return;
      }
      let oldIsActive = this.isActive;
      this.oldIsActive = oldIsActive;
      this.isActive = value.path;
      let routeItem = this.tabList.find((item) => item.path === value.path);
      if (!routeItem) {
        this.tabList.push({
          name: this.$route.name,
          path: this.$route.path,
          title: this.$route.meta.title || this.$route.params.title,
        });
      }
    },
  },
};
</script>
<style lang="less" scoped>
@import "../../assets/css/tabbar/tabbar.less";
</style>
