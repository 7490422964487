<template>
  <div class="m_navbar_content">
    <div class="m_logo_content"></div>
    <div class="m_menu_content">
      <el-menu
        :default-active="active"
        class="el-menu-vertical-demo"
        :collapse="isCollapse"
        router
      >
        <el-submenu :index="item.id" v-for="item in navList" :key="item.id">
          <template slot="title">
            <i :class="item.icon"></i>
            <span slot="title">{{ item.name }}</span>
          </template>
          <el-menu-item
            :index="childItem.id"
            v-for="childItem in item.children"
            :key="childItem.id"
            >{{ childItem.name }}</el-menu-item
          >
        </el-submenu>
      </el-menu>
    </div>
  </div>
</template>
<script>
import Bus from "@/utils/bus";
export default {
  name: "mNavbar",
  directives: {},
  data() {
    return {
      isCollapse: false,
      active: "/authority",
      navList: [
        {
          name: "角色管理",
          id: "2",
          icon: "el-icon-s-custom",
          children: [
            {
              name: "角色列表",
              id: "/role",
            },
          ],
        },
        {
          name: "账号管理",
          id: "3",
          icon: "el-icon-user-solid",
          children: [
            {
              name: "账号列表",
              id: "/user",
            },
          ],
        },
        {
          name: "加盟商管理",
          id: "5",
          icon: "el-icon-user",
          children: [
            {
              name: "加盟商列表",
              id: "/franchisee",
            },
          ],
        },
        {
          name: "区域点位管理",
          id: "6",
          icon: "el-icon-position",
          children: [
            {
              name: "区域点位列表",
              id: "/areaPoint",
            },
          ],
        },
        {
          name: "设备管理",
          id: "7",
          icon: "el-icon-mobile",
          children: [
            {
              name: "设备列表",
              id: "/equipment",
            },
          ],
        },
        {
          name: "商品管理",
          id: "8",
          icon: "el-icon-s-shop",
          children: [
            {
              name: "商品列表",
              id: "/goods",
            },
          ],
        },
        {
          name: "运营管理",
          id: "9",
          icon: "el-icon-tickets",
          children: [
            {
              name: "区域零售价格调整",
              id: "/regionPrice",
            },
            {
              name: "加盟商调价权限",
              id: "/patnerPrice",
            },
            {
              name: "设备温控策略",
              id: "/vmtempControl",
            },
          ],
        },
        {
          name: "报表查询",
          id: "11",
          icon: "el-icon-s-data",
          children: [
            {
              name: "机器销售统计报表",
              id: "/vmSaleReport",
            },
            {
              name: "商品销售统计报表",
              id: "/goodSaleReport",
            },
            {
              name: "机器商品销售查询",
              id: "/vmgoodSale",
            },
            {
              name: "退款统计报表",
              id: "/refundReport",
            },
            {
              name: "退款查询",
              id: "/refund",
            },
            {
              name: "补撤货统计报表",
              id: "/replenishReport",
            },
            {
              name: "补撤货查询",
              id: "/replenish",
            },
            {
              name: "库存统计报表",
              id: "/stockReport",
            },
            {
              name: "库存查询",
              id: "/stock",
            },
            {
              name: "设备故障统计",
              id: "/vmfailReport",
            },
            {
              name: "设备故障查询",
              id: "/vmfail",
            },
            {
              name: "运行时长统计",
              id: "/runTime",
            },
            {
              name: "广告播放日志",
              id: "/advertPlaylog",
            },
          ],
        },
      ],
    };
  },
  props: {
    borderData: {},
  },
  computed: {},
  components: {},
  created() {
    this.active = this.$route.path;
  },
  mounted() {
    Bus.$on("foldChange", (data) => {
      this.isCollapse = data;
    });
  },
  methods: {},
  watch: {
    $route(value) {
      this.active = value.path;
    },
  },
};
</script>
<style lang="less" scoped>
@import "../../assets/css/navbar/navbar.less";
</style>
